var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"c-app flex-row align-items-center"},[_c('CContainer',[_c('CRow',{staticClass:"justify-content-center"},[_c('CCol',{attrs:{"md":"5"}},[_c('CCardGroup',[_c('CCard',{staticClass:"p-4"},[_c('CCardBody',[_c('h1',{staticClass:"text-center"},[_vm._v("Login")]),_c('p',{staticClass:"text-muted text-center"},[_vm._v("Sign In to your account")]),_c('b-alert',{staticClass:"text-white bg-danger",attrs:{"show":_vm.errorAlert,"variant":" ","dismissible":"","fade":""}},[_c('div',{staticClass:"iq-alert-icon"},[_c('i',{staticClass:"ri-information-line"}),_vm._v(" "+_vm._s(_vm.errorMsg)+" ")])]),_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.login)},"reset":function($event){$event.preventDefault();return reset.apply(null, arguments)}}},[_c('ValidationProvider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label":"Email:","label-for":"email"}},[_c('b-form-input',{attrs:{"id":"email","placeholder":"","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"password","rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row mt-3",attrs:{"label-cols-sm":"12","label":"Password:","label-for":"password"}},[_c('b-form-input',{attrs:{"id":"password","type":"password","placeholder":"","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)}),_c('div',{staticClass:"custom-control custom-checkbox"},[_c('input',{staticClass:"custom-control-input",attrs:{"type":"checkbox","id":"customControlInline"}}),_c('label',{staticClass:"custom-control-label",attrs:{"for":"customControlInline"}},[_vm._v("Remember me")])]),_c('div',{staticClass:"mt-3"},[_c('button',{staticClass:"btn btn-primary btn-block",attrs:{"type":"submit"}},[_vm._v("Log In")])]),_c('div',{staticClass:"mt-4 text-center"},[_c('a',{attrs:{"href":"#"}},[_c('i',{staticClass:"mdi mdi-lock mr-1"}),_vm._v(" Forgot your password?")])])],1)]}}])})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }